import React, { useState } from "react"
import BackgroundImage from "gatsby-background-image"
import Img from "gatsby-image"

import { PageLayout } from "../components/Layout"
import { useStaticQuery, graphql } from "gatsby"
import {
  TextSection1,
  TextSection2,
  TextSection3,
  TextSection4,
} from "../components/TextSection"
import SEO from "../components/SEO"

import { ActionButton } from "../components/buttons"

import "../sass/typography.scss"
import styled from "styled-components"

import AwesomeSlider from "react-awesome-slider"
import BackgroundSlider from 'react-background-slider'
import withAutoplay from "react-awesome-slider/dist/autoplay"
import "react-awesome-slider/dist/styles.css"

import "lightbox-react/style.css"


const AutoplaySlider = withAutoplay(AwesomeSlider)

const VideoSection = styled.div`
  width: 100%;
  height: 100vh;
  min-height: 720px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #aba17b;
  @media all and (max-width: 1280px) {
    height: auto;
    min-height: unset;
  }
`

const Video = () => (
  <VideoSection>
    <div style={{width: "1280px"}}>
      <div style=
      {{
        position: "relative",
        overflow: "hidden",
        width: "100%",
        paddingTop: "56.25%"      
      }}
      >
        <iframe
        width="100%"
        height="100%"
        style={{borderWidth:"0" , position:"absolute" , top:"0" , left:"0" , bottom:"0" , right:"0"}}
        allow="fullscreen"
        src="https://www.youtube.com/embed/XjN0YFh2M-0?autoplay=0"
        ></iframe>
      </div>      
    </div>    
  </VideoSection>
)

const TitleBlock = styled.div`
  
  background-color: rgb(49 49 49 / 40%);
  padding: 0 3.5em;
  margin-bottom: 2em;
  border-radius: 5px;
  h3 {
    font-size: 50px;
    margin-bottom: 5px;
    font-family: "Playfair Display";
    color: white;
    font-weight: 200;
    text-align: center;
  }
  h6 {
    font-size: 16px;
    color: white;    
    font-weight: 400;
    font-family: "Raleway";
    text-align: center;
    margin-top: 1em;
    margin-bottom: 3rem;
  }
  @media all and (max-width: 1280px) {
    h3 {
      font-size: 40px;
    }
  }
`

const Banner = styled.div`
  height: calc(100vh - 200px);
  position: relative; 
  min-height: 500px;
  @media all and (max-height: 700px) {
    .scroll-icon {
      display: none;
    }
  }
  
`

export default () => {
  const [isOpen, setIsOpen] = useState(false)
  const data = useStaticQuery(graphql`
    query {
      backgrounds: allContentfulSliders {
        nodes: nodes {
          contentful_id
          childImageSharp: image {
            fluid(maxWidth: 2000, quality: 90) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
        }
      }
      text: allContentfulSliders {
        edges {
          node {
            header
            subHeader
            id: contentful_id
          }
        }
      }
      images: contentfulGallery(name: { eq: "Main Page" }) {
        images {
          fluid(maxWidth: 1600, quality: 90) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
      }
      activityIcons: allFile(
        filter: { sourceInstanceName: { eq: "activityIcons" } }
        sort: { fields: name }
      ) {
        nodes {
          childImageSharp {
            fixed(width: 50, height: 50) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    }
  `)

  return (
    <PageLayout>
      <SEO 
      title="Big 5 Game Reserve South Africa" 
      description="Mavela Game Lodge is a luxury tented camp located on the Manyoni Game Reserve, offering an all-inclusive package with Big 5 game drives and walking safaris" 
      />

      <Banner>
        <div className="slider-wrapper">
          <AutoplaySlider
            play={!isOpen}
            interval={6000}
            fillParent={true}
            bullets={false}
            startup={true}
            startupScreen={<div style={{ backgroundColor: "#444444" }}></div>}
          >
            {data.backgrounds.nodes.map((x: any, i: number) => {
              return (
                <div className="slider-block" key="contentful_id">
                  <BackgroundImage fluid={x.childImageSharp.fluid}></BackgroundImage>
                </div>
              )
            })}
          </AutoplaySlider>
          <div className="slider-overlay">
            <div className="caption-container">
              <TitleBlock>
                <h3>Luxury Safari-Style Tented Camp</h3>
                <h6>
                  Escape back to nature; experience tranquility and adventure.
                </h6>
              </TitleBlock>
              <div style={{textAlign:"center"}}>
                <ActionButton style={{ margin: "10px" }}>
                  <a
                    href="https://www.nightsbridge.co.za/bridge/book?bbid=16200"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Check Availability
                  </a>
                </ActionButton>
                <ActionButton style={{ margin: "5px 10px" }}>
                  <a
                    href="/special-rates"
                    rel="noopener noreferrer"
                  >
                    Current Specials
                  </a>
                  
                </ActionButton>              
              </div>
            </div>
            <a href="#ts1"><div className="scroll-icon"></div></a>            
          </div>
        </div>
      </Banner>
 
      
      <TextSection1 images={data.activityIcons} />
      <Video/>
      <TextSection2 />
      <Img fluid={data.images.images[1].fluid} />
      <TextSection3 />
      <TextSection4 />
    </PageLayout>
  )
}
