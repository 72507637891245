import React from "react"
import { TextCenter, Row } from "./General"
import { SectionHeader, SubSectionHeader } from "./Typography"
import { Link, useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import { ActionButton } from "../components/buttons"
import EnquireButton from "../components/EnquireButton"
import TextSectionBackground from "./TextSectionBackground"
import Img from "gatsby-image"
import Review from "../components/Review"
import Blog from "../components/Blog"

const ActivityWrapper = styled.div`
  maxWidth: 650px; 
  marginBottom: 60px;
  @media all and (max-width: 980px) {
    margin-bottom: 30px;
  }
`

const ActivityHeader = styled.h6`
  font-family: "Playfair Display", Georgia, "Times New Roman", serif;
  font-weight: 500;
  color: #3c3c3c;
  font-size: 18px;
  margin: 20px 0;
  word-break: break-word;
`

const ActivityDescription = styled.p`
  font-family: "Playfair Display", Georgia, "Times New Roman", serif;
  font-weight: 500;
  font-size: 14px;
  color: #666;
  font-weight: 500;
`

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 100px;
  grid-auto-rows: 200px;
  margin-top: 30px;
  margin-bottom: 50px;
  @media only screen and (max-width: 980px) {
    display: block;
    margin-bottom: 20px;
  }
`
const Inner = styled.div`
  max-width: 1400px;
  margin: 0 auto;
  
`

const ButtonWrapper = styled.div`
  text-align: center;
  margin: 2rem 0;
  button {
    margin: 8px 10px;
  }
`

const ActivitySection = ({ img, header, description, link }: any) => {
  return (
    <Row fd="column" ai="center">
      <ActivityWrapper>
        {link.includes("https") ? (
          <a
            href="https://www.google.com/maps/place/Mavela+Game+Lodge/@-27.713205,32.0153503,17z/data=!3m1!4b1!4m8!3m7!1s0x1efaca3c017a91b3:0xa86152d5e4bc09b0!5m2!4m1!1i2!8m2!3d-27.713205!4d32.017539"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Img fixed={img} />
          </a>
        ) : (
          <Link to={link}>
            <Img fixed={img} />
          </Link>
        )}
        <ActivityHeader>{header}</ActivityHeader>
        <ActivityDescription>{description}</ActivityDescription>
      </ActivityWrapper>
    </Row>
  )
}

const TextSection1 = ({ images }: any) => {
  return (
    <TextSectionBackground id="ts1">
      <Inner>
        <TextCenter pa="1rem 0">
          <SectionHeader>An African Wildlife Experience you will never forget</SectionHeader>
        </TextCenter>
        <Grid>
          <ActivitySection
            img={images.nodes[5].childImageSharp.fixed}
            header="SAFARI-STYLE TENTED ACCOMMODATION"
            description="Accommodation at Mavela Game Lodge is in five luxury, en suite
              safari-style tents. Each room has a private deck with beautiful
              views over the surrounding bushveld."
            link="/gallery#tents"
          />
          <ActivitySection
            img={images.nodes[1].childImageSharp.fixed}
            header="ALL INCLUSIVE MEALS & DRINKS"
            description="Early morning coffee and rusks (pre-drive), full English and continental breakfast, Light lunch (pre afternoon drive) and a 3-course dinner. All drinks including good quality wines."
            link="/gallery#meals"
          />
          <ActivitySection
            img={images.nodes[2].childImageSharp.fixed}
            header="LOCATION"
            description="We are located in the 23,000 hectare Manyoni Private Game Reserve, in the Mkuze Valley Lowveld in Northern KwaZulu-Natal."
            link="https://www.google.com/maps/place/Mavela+Game+Lodge/@-27.713205,32.0153503,17z/data=!3m1!4b1!4m8!3m7!1s0x1efaca3c017a91b3:0xa86152d5e4bc09b0!5m2!4m1!1i2!8m2!3d-27.713205!4d32.017539"
            target="_blank"
          />
          <ActivitySection
            img={images.nodes[4].childImageSharp.fixed}
            header="BIG 5 & WILDLIFE"
            description="The Reserve is home to over 70 mammal species including Cheetah, Wild Dog, Hyena, Giraffe, Kudu, Nyala, Wildebeest, Zebra and the Big 5 (Lion, Leopard, Buffalo, Elephant & Rhino)."
            link="/gallery#big5"
          />
          <ActivitySection
            img={images.nodes[0].childImageSharp.fixed}
            header="BIRD WATCHING"
            description="The game reserve is renowned for its exceptional birding opportunities with over 420 bird species recorded on the property."
            link="/gallery#birds"
          />
          <ActivitySection
            img={images.nodes[3].childImageSharp.fixed}
            header="LODGE"
            description="The Lodge comprises of an open-plan, two story thatched welcome area with a lounge, bar and dining facilities leading out to the pool and wooden veranda."
            link="/gallery#lodge"
          />
        </Grid>
      </Inner>      
    </TextSectionBackground>
  )
}

const TextSection2 = () => {
  return (
    <TextSectionBackground>
      <Inner>
        <TextCenter pa="1rem 0">
          <SectionHeader>Safari-Style Tented Accommodation</SectionHeader>
          <SubSectionHeader>Get closer to the outdoors in style</SubSectionHeader>
          <ActivityDescription>
            Accommodation is in seven, luxury, safari-style tents with en suite
            shower facilities and a private deck with beautiful views over the
            surrounding bushveld. With comfortable deck chairs and a birdbath in
            front of each tent, birding enthusiasts can enjoy the bird life with
            ease from their rooms. Crisp white linen is used on either king or
            single beds, the tents are tastefully decorated complete with aircons, standing
            fans and luxurious Charlotte Rhys amenities. The camp is unfenced and
            guests are escorted back to their rooms after dinner. Mavela Game
            Lodge offers an intimate safari lodge experience in a unique part of
            the African bush.
          </ActivityDescription>
          <ButtonWrapper>
            <EnquireButton />
            <ActionButton ma="20px 0 60px 15px">
              <Link target="_blank" to="https://book.nightsbridge.com/16200">
                Check Availability
              </Link>
            </ActionButton>
          </ButtonWrapper>          
        </TextCenter>
      </Inner>      
    </TextSectionBackground>
  )
}

const TextSection3 = () => {
  return (
    <TextSectionBackground style={{background:"#ABA17B" , color:"white"}}>
      <Inner>
        <TextCenter pa="1rem 0" style={{filter:"contrast(6)"}}>
          <SectionHeader>
            Guest Experiences
          </SectionHeader>
        </TextCenter>
        <Review></Review>
      </Inner>
          </TextSectionBackground>
  )
}

const TextSection4 = () => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulBlog(limit: 3, sort: { fields: time, order: DESC }) {
        nodes {
          header
          time
          slug
          shortText
          contentful_id
          image {
            fixed(width: 350, height: 225) {
              ...GatsbyContentfulFixed_withWebp
            }
          }
        }
      }
    }
  `)
  return (
    <TextSectionBackground>
      <TextCenter>
        <SectionHeader>Latest News</SectionHeader>
        <SubSectionHeader>Whats been happening at Mavela</SubSectionHeader>
        <Row fw="wrap"></Row>
      </TextCenter>
      <Blog allContentfulBlog={data.allContentfulBlog}></Blog>
    </TextSectionBackground>
  )
}

export { TextSection1, TextSection2, TextSection3, TextSection4 }
